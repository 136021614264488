import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Button, Image, InnerHTML, Section, SectionTitle } from "components/shared";
import { useResponsiveSizes } from "components/shared/hooks";
import { selectSharedLayoutContent } from "components/shared/layout/store/layoutContentSlice";
import { animate, m, useInView, useMotionValue } from "framer-motion";
import clsx from "lib/clsx";
import useFlubber from "lib/flubber/useFlubber";
import { RootState, useAppSelector } from "lib/redux";
import { IHeroSection } from "models/main/shared";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef } from "react";
import Typewriter from "typewriter-effect";

const paths = [
  "M1015.5 91c190 9.6 376.1-55.3 445.5-89v-20H-19V91a996 996 0 0 1 357.4-79c168.8-6 439.6 67 677 79Z",
  "M1006.5 92.4c184.8 6.3 366-36.7 433.5-59V0H0v92.4C63 74.2 183.6 44 347.8 40s427.7 44.4 658.7 52.4",
];

interface IProps {
  selector: (state: RootState) => IHeroSection;
  imageAspectRatio?: [number, number];
}

const HeroSection = ({ selector, imageAspectRatio }: IProps) => {
  const { title, titleRotateWords, subtitle, content, button, image } = useAppSelector(selector);
  const {
    settings: { enablePageTransition },
  } = useAppSelector(selectSharedLayoutContent);

  const { pathname } = useRouter();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { getSizes } = useResponsiveSizes();

  const progressRange: [number, number] = useMemo(() => [0, 1], []);
  const waveAnimate = useMotionValue(0);
  const path = useFlubber(waveAnimate, progressRange, paths, 11);

  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (smDown) return;
    if (!isInView) {
      waveAnimate.set(0);
      return;
    }

    animate(...progressRange, {
      stiffness: 100,
      damping: 10,
      duration: 1.2,
      delay: enablePageTransition ? 0.4 : undefined,
      onUpdate: waveAnimate.updateAndNotify,
    });
  }, [waveAnimate, progressRange, smDown, isInView, enablePageTransition]);

  if (!!image?.url && !imageAspectRatio) {
    console.debug(
      `This page header (${pathname}) is not suppose to have an image. \`imageAspectRatio\` parameter has not been provided.`,
    );
  }

  const HeroImage = ({
    wrapperClassName,
    className,
  }: {
    wrapperClassName?: string;
    className?: string;
  }) =>
    image && imageAspectRatio ? (
      <Box
        className={clsx("w-full sm:w-1/2", wrapperClassName)}
        sx={{
          aspectRatio: imageAspectRatio.join("/"),
        }}
      >
        <Image
          className={clsx("h-full w-full", className)}
          {...image}
          placeholder="empty"
          priority
          sizes={getSizes(
            [
              { breakpoint: "sm", size: "100vw" },
              { breakpoint: "md", size: imageAspectRatio.map((r) => r * 0.85) as [number, number] },
              {
                breakpoint: "lg",
                size: imageAspectRatio.map((r) => r * 0.751) as [number, number],
              },
              {
                breakpoint: "xl",
                size: imageAspectRatio.map((r) => r * 0.935) as [number, number],
              },
              { size: imageAspectRatio },
            ],
            image.width!,
            image.height!,
          )}
        />
      </Box>
    ) : null;

  return (
    <Section className="!py-0">
      <div ref={ref} className="overflow-hidden">
        <div className="bg-primary-dark pb-16 pt-14 text-dark lg:pb-12 lg:pt-24">
          <div className="container">
            <div className="sm:container-inner flex flex-col items-center gap-16 sm:flex-row">
              <div className="flex w-full flex-col gap-4 sm:w-1/2">
                <header className="mb-6 space-y-4">
                  {subtitle && (
                    <Typography className="text-balance font-normal" component="h2" variant="p1">
                      {subtitle}
                    </Typography>
                  )}
                  <div>
                    <SectionTitle className="text-balance" variant="h1">
                      {title} <span className="sr-only">expert</span>
                      {!!titleRotateWords?.length && (
                        <div aria-hidden>
                          <Typewriter
                            options={{
                              cursorClassName: "Typewriter__cursor text-secondary-main",
                              strings: titleRotateWords.map((rw) => rw.word),
                              autoStart: true,
                              loop: true,
                            }}
                          />
                        </div>
                      )}
                    </SectionTitle>
                  </div>
                </header>
                {content && (
                  <InnerHTML
                    className="text-balance"
                    variant="p1"
                    sx={{
                      "p:not(:first-child)": {
                        mt: "-2rem",
                      },
                    }}
                  >
                    {content}
                  </InnerHTML>
                )}
                {button?.path && (
                  <Button
                    className="mt-10 w-fit"
                    LinkComponent={Link}
                    href={button.path}
                    size="large"
                    variant="contained"
                    color="secondary"
                  >
                    {button.text}
                  </Button>
                )}
              </div>
              {
                <HeroImage
                  className="rounded-2xl bg-primary-dark"
                  wrapperClassName="hidden sm:block"
                />
              }
            </div>
          </div>
        </div>
        <HeroImage wrapperClassName="flex sm:hidden bg-primary-dark" />
        <svg
          className="-ml-2 -mt-0.5 hidden sm:block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 93"
        >
          <m.path d={path} fill="var(--palette-primary-dark)" />
        </svg>
      </div>
    </Section>
  );
};

export default HeroSection;
